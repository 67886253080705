import React from 'react';

import { useResponsive } from 'src/hooks/responsive';

import css from './MakoAnalytics.module.scss';

const IS_LOCAL = process.env.ENV === 'local';

interface Props {
  vcmId: string;
}

const MakoAnalytics = (props: Props) => {
  const [isMatchingMQ] = useResponsive(['MOBILE']);
  const { vcmId } = props;

  const renderAnalyticsIframe = () => {
    const srcBase = 'https://mobileapp.mako.co.il/metricsCall.html';
    const mako_ExternalProjectID = 'EX_gazawar';
    const channelId = '7d61bdd9ccbc4310VgnVCM2000002a0c10acRCRD';
    const platform = isMatchingMQ ? 'mobile' : 'web';
    const isMainPage = vcmId === 'HP';
    const contentType = isMainPage ? vcmId : 'content';
    const ref = (typeof window !== 'undefined' && document.referrer) || '';

    const params = [
      ['vcmId', vcmId],
      ['contentType', contentType],
      ['channelId', channelId],
      ['mako_ExternalProjectID', mako_ExternalProjectID],
      ['platform', platform],
      ['ref', ref],
    ];

    const src = `${srcBase}?${new URLSearchParams(params).toString()}`;
    return (
      // putting styles inside an <object /> or an <embed />
      // breaks them in chrome no matter how you insert them
      <object className={css.analytics} type="text/html" data={src} width="0" height="0" />
    );
  };

  if (IS_LOCAL) {
    return null;
  } else {
    return renderAnalyticsIframe();
  }
};

export default MakoAnalytics;
