import React from 'react';
import cn from 'classnames';

import { useAppState, useAppDispatch, PersonInfo, SortOptions } from 'src/Context';
import { CANDLES_AMOUNT_LS_KEY, PERSONS_CANDLES_LS_KEY } from 'src/constants';
import { usePlacesAndUnits } from 'src/hooks/use-places-and-units';
import MakoAnalytics from 'src/components/common/MakoAnalytics';
import FiltersPopup from 'src/components/common/FiltersPopup';
import PersonModal from 'src/components/common/PersonModal';
import { useResponsive } from 'src/hooks/responsive';
import { useFiltered } from 'src/hooks/use-filtered';
import { updateCandlesAmount } from 'src/api';
import images from 'src/static/assets';
import { analytics } from 'src/utils';

import GridSkeleton from 'src/components/common/GridSkeleton';
import Persons from './Persons';
import Socials from '../Socials';

import css from './MainPage.module.scss';

const { desktopBg, mobileBg, makoLogo, whatsapp, advancedFiltersIcon, makoLogoSvg } = images;

const GA_FILTER_OPS = {
  az: 'א-ב',
  recent: 'עדכני ביותר',
};

/**
 * a - rank ?
 * b - first name
 * c - last name
 * d - age (number)
 * e - sex ?
 * f - place
 * g - symbol ?
 * h - age(d) + sub-text
 * i - age[d] + sub-text + place[f]
 * j - image link
 * k - unit
 * l - description
 */

const PAGE_SIZE = 100;

const MainPage = () => {
  const { filters, sorting, currentPage, isFetching } = useAppState();
  const dispatch = useAppDispatch();
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [isMobile] = useResponsive('MOBILE');

  const [isShowFilters, setIsShowFilters] = React.useState<boolean>(false);
  const [isAnimation, setIsAnimation] = React.useState<boolean>(false);
  const [isSticky, setIsSticky] = React.useState<boolean>(false);
  const { items, total } = useFiltered({ pageSize: PAGE_SIZE });
  const { units, places } = usePlacesAndUnits();
  const [lsPersonsCandels, setLSPersonsCandels] = React.useState<string[]>([]);

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll);

    const lsPersonsCandles = JSON.parse(window.localStorage.getItem(PERSONS_CANDLES_LS_KEY) || '[]');
    setLSPersonsCandels(lsPersonsCandles);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const onPersonCandleBtnClick = (person: PersonInfo) => {
    const currentCandlesAmount = window.localStorage.getItem(CANDLES_AMOUNT_LS_KEY);
    const currentPersonsCandles = JSON.parse(window.localStorage.getItem(PERSONS_CANDLES_LS_KEY) || '[]');

    if (!currentPersonsCandles.includes(person.id as string)) {
      window.localStorage.setItem(CANDLES_AMOUNT_LS_KEY, `${Number(currentCandlesAmount || 0) + 1}`);
      window.localStorage.setItem(PERSONS_CANDLES_LS_KEY, JSON.stringify([...currentPersonsCandles, person.id]));
      setLSPersonsCandels([...currentPersonsCandles, person.id]);
      dispatch({ type: 'INCREASE_CANDLES_AMOUNT' });

      updateCandlesAmount();
    }

    dispatch({ type: 'SET_SELECTED_PERSON', payload: person });
    dispatch({ type: 'SET_IS_PERSON_MODAL_VISIBLE', payload: true });
  };

  const onSearchBtnClick = () => {
    if (!searchInputRef.current?.value) {
      return;
    }

    setIsAnimation(true);

    setTimeout(() => {
      setIsAnimation(false);
    }, 1000);

    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_FILTERS', payload: { search: searchInputRef.current?.value || '' } });

    analytics.gtag.event('search', { CUSTOM_PARAMETER: filters.search });
  };

  const onPrevClick = () => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 });
    scrollTop();
    analytics.gtag.event('previous page');
  };

  const onNextClick = () => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage + 1 });

    scrollTop();
    analytics.gtag.event('Next page');
  };

  const clickOnClear = () => {
    dispatch({ type: 'SET_FILTERS', payload: { search: '' } });
    dispatch({ type: 'SET_SORTING', payload: SortOptions.RECENT });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });

    if (searchInputRef.current) searchInputRef.current.value = '';
  };

  const isNextBtnDisabled = () => currentPage * PAGE_SIZE >= total;
  const isPaginationVisible = () => total > PAGE_SIZE;

  const isSomeFiltersSelected = () => {
    const { search, ...other } = filters;

    return Object.values(other).some((f) => f !== '');
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchInputRef.current?.value) {
      onSearchBtnClick();
    }
  };

  const scrollTop = () => {
    const rootElement = document.documentElement;
    rootElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onFilterBtnClick = () => {
    analytics.gtag.event('חיפוש מתקדם');
    setIsShowFilters(true);
  };

  const onSortingClick = (sortType: SortOptions) => {
    analytics.gtag.event('Ordering', { CUSTOM_PARAMETER: GA_FILTER_OPS[sortType] });

    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_SORTING', payload: sortType });
  };

  const clearAdvancedFilters = () => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_FILTERS', payload: { role: '', unit: '', place: '' } });
    dispatch({ type: 'SET_SORTING', payload: SortOptions.RECENT });
  };

  const addAdvancedFilters = (f: Omit<typeof filters, 'search'>) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 });
    dispatch({ type: 'SET_FILTERS', payload: f });
    setIsAnimation(true);

    setTimeout(() => {
      setIsAnimation(false);
    }, 1000);
  };

  return (
    <div className={css.pageWrapper}>
      <div className={css.fixedBg} style={{ backgroundImage: `url(${isMobile ? mobileBg : desktopBg})` }} />
      <header className={cn(css.header, isSticky && css.sticky)}>
        <a
          href="https://www.mako.co.il"
          target="_blank"
          rel="noopener noreferrer"
          className={css.makoLink}
          onClick={() => analytics.gtag.event('logo', { CUSTOM_PARAMETER: 'mako' })}
        >
          <img src={makoLogoSvg} alt="mako logo" />
        </a>

        <div className={css.title} onClick={clickOnClear}>
          <span>נר </span> לזכרם
        </div>
        <Socials />
      </header>
      <div className={css.topSection}>
        <a href="https://www.mako.co.il" target="_blank" rel="noopener noreferrer" className={css.makoLink}>
          <img src={makoLogoSvg} alt="mako logo" />
        </a>

        <h1 className={css.title}>
          <span>נר </span> לזכרם
        </h1>
        <h3 className={css.subTitle}>פרויקט הנצחה לנרצחים ולנופלים במלחמת חרבות ברזל</h3>
        <p className={css.text} dangerouslySetInnerHTML={{ __html: TEXTS.subTitle }} />
      </div>
      <div className={css.searchContainer}>
        <div className={cn(css.searchWrapper)}>
          <div className={css.inputWrapper}>
            <form action="" onSubmit={onSubmit}>
              <input ref={searchInputRef} type="search" placeholder="חפשו שם" />
            </form>
            {searchInputRef.current?.value !== '' && <div className={css.clearBtn} onClick={clickOnClear}></div>}
          </div>

          <button className={cn(css.searchBtn)} onClick={onSearchBtnClick}>
            <svg className={css.searchIcon} width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.65 0C2.989 0 0 2.989 0 6.65c0 3.661 2.989 6.65 6.65 6.65a6.61 6.61 0 0 0 4.345-1.633l.405.405V13.3l5.7 5.7 1.9-1.9-5.7-5.7h-1.228l-.405-.405A6.61 6.61 0 0 0 13.3 6.65C13.3 2.989 10.311 0 6.65 0Zm0 1.9a4.736 4.736 0 0 1 4.75 4.75 4.736 4.736 0 0 1-4.75 4.75A4.736 4.736 0 0 1 1.9 6.65 4.736 4.736 0 0 1 6.65 1.9Z"
                fill="#de0000"
              />
            </svg>
          </button>
        </div>
        <div className={cn(css.advancedSearchBtn, isSomeFiltersSelected() && css.active)} onClick={onFilterBtnClick}>
          <img src={advancedFiltersIcon} alt="" />
        </div>
      </div>
      {items.length !== 0 && (
        <div className={css.sorting}>
          <button
            type="button"
            className={cn(css.reverseSorting, css.sortBtn, sorting === SortOptions.RECENT && css.active)}
            onClick={() => onSortingClick(SortOptions.RECENT)}
          >
            העדכני ביותר
          </button>
          <button
            type="button"
            className={cn(css.AZSorting, css.sortBtn, sorting === SortOptions.AZ && css.active)}
            onClick={() => onSortingClick(SortOptions.AZ)}
          >
            לפי א'-ב'
          </button>
        </div>
      )}
      {items.length === 0 && !isFetching && <div className={css.noSearchResults}>לא נמצאו תוצאות</div>}
      {items.length === 0 && isFetching && <GridSkeleton />}
      {items.length !== 0 && (
        <>
          <div className={css.itemsGrid}>
            <Persons
              showItems={items}
              isAnimation={isAnimation}
              lsCandles={lsPersonsCandels}
              onPersonCandleBtnClick={onPersonCandleBtnClick}
            />
          </div>
          {isPaginationVisible() && (
            <div className={css.btns}>
              <div className={cn(css.prevBtn, currentPage === 1 && css.disabled)} onClick={onPrevClick}>
                &lsaquo; לעמוד הקודם
              </div>
              <div className={cn(css.divider, (currentPage === 1 || isNextBtnDisabled()) && css.disabled)}></div>
              <div className={cn(css.nextBtn, isNextBtnDisabled() && css.disabled)} onClick={onNextClick}>
                לעמוד הבא &rsaquo;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
          )}
        </>
      )}
      <div className={css.contact}>
        <a href="https://wa.me/972502326004" target="_blank" rel="noopener noreferrer" className={css.whatsappLink}>
          <img src={whatsapp} alt="whatsapp icon" />
          <span>מצאתם טעות? כתבו לנו בוואטסאפ האדום של mako &rsaquo;</span>
        </a>
      </div>
      <a
        href="https://www.mako.co.il/help-5bb6bf4ccf39c110/Article-b7ff18a9de86471026.htm?partner=NewsfooterLinks"
        target="_blank"
        rel="noopener noreferrer"
        className={css.accessibilityLink}
      >
        הסדרי נגישות
      </a>
      {isShowFilters && (
        <FiltersPopup
          places={places}
          units={units}
          closePopup={() => setIsShowFilters(false)}
          activeFilters={filters}
          clearFilters={clearAdvancedFilters}
          setFilters={addAdvancedFilters}
        />
      )}
      <PersonModal />
      <MakoAnalytics vcmId="HP" />
    </div>
  );
};

const TEXTS = {
  subTitle: `ב-7 באוקטובר 2023 חדרו מרצחי ארגון הטרור חמאס לישראל וטבחו במאות חפים מפשע. בקרבות נפלו רבים מכוחות הביטחון וההצלה. הדליקו נר לזכר הקורבנות. נזכור אותם לעד`,
};

export default MainPage;
