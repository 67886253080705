import React from 'react';
import cn from 'classnames';
import { useResponsive } from 'src/hooks/responsive';
import { DEFAULT_IMAGE } from 'src/constants';
import { PersonInfo } from 'src/Context';
import images from 'src/static/assets';

import css from './Persons.module.scss';

const { symbol1Src, symbol2Src, fireFighterRoleIconSrc, candleIcon, symbol3Src } = images;

interface Props {
  showItems: PersonInfo[];
  lsCandles: string[];
  isAnimation: boolean;
  onPersonCandleBtnClick: (p: PersonInfo) => void;
}

const Persons = ({ showItems, isAnimation, onPersonCandleBtnClick, lsCandles }: Props) => {
  const [isMobile] = useResponsive('MOBILE');
  const clickOnPerson = (p: PersonInfo) => {
    console.log('HERERE', p);

    onPersonCandleBtnClick(p);
  };

  const renderRoleImage = (info: PersonInfo) => {
    if (info.g) {
      switch (info.g) {
        case 'חייל':
          return (
            <div className={css.icon}>
              <img src={symbol1Src} alt="symbol" />
            </div>
          );
        case 'שוטר':
          return (
            <div className={css.icon}>
              <img src={symbol2Src} alt="symbol" />
            </div>
          );
        case `שב"כ`:
          return (
            <div className={css.icon}>
              <img src={symbol3Src} alt="symbol" />
            </div>
          );
        case 'כבאי':
          return (
            <div className={css.icon}>
              <img src={fireFighterRoleIconSrc} alt="fire-fighter" />
            </div>
          );

        default:
          return null;
      }
    }
  };
  const renderItems = () => {
    return showItems.map((i, index: number) => {
      return (
        <div className={cn(css.gridItem, isAnimation && css.animation)} key={`${i.a}${i.b}${i.c}${index}`}>
          <div className={css.photo}>
            <img src={`${i.j || DEFAULT_IMAGE}`} alt={`${i.a || ''} ${i.b || ''} ${i.c || ''}`.trim()} />
          </div>
          <div className={css.infoWrap}>
            <div className={css.info}>
              <div className={css.top}>
                <div className={css.texts}>
                  <div className={css.name}>
                    {i.a && `${i.a} `}
                    {i.b} {i.c}
                  </div>
                  <div className={css.subName}>
                    {i.i}
                    {i.k && `, ${i.k}`}
                  </div>
                  <div className={css.description}>{i.l}</div>
                </div>
              </div>
            </div>
            {!isMobile && renderRoleImage(i)}
          </div>
          {isMobile && renderRoleImage(i)}
          <button
            type="button"
            className={cn(css.button, lsCandles.includes(i.id as string) && css.isButtonAnimated)}
            onClick={() => clickOnPerson(i)}
          >
            <img src={candleIcon} alt="candle icon" />
            {lsCandles.includes(i.id as string) ? <span>הדלקתי נר</span> : <span>להדלקת נר זיכרון</span>}
          </button>
        </div>
      );
    });
  };

  return <>{renderItems()}</>;
};

export default Persons;
